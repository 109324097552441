import Vue from "vue";
import Vuetify from "vuetify";
import { validate, default_controller, opts_vuefity } from "./helpers.js";

Vue.use(Vuetify)

const app = new Vue({
    el: '#app',
    vuetify: new Vuetify(opts_vuefity),
    data:() => ({
        controller : default_controller,
        rules: validate.rules,
        valid : false,
        show1:false,
        termsAccepted: false
    }),
    methods : {
        login(){
            if (!this.termsAccepted) {
                alert('Debe aceptar los términos y condiciones para continuar');
                return;
            }
            this.controller.loading = true;
            this.controller.row_data.email = this.ensureEmailDomain(this.controller.row_data.email);
            axios.post("/login", this.controller.row_data, { headers: { 'Content-Type': 'application/json' } })
                .then( response => {
                    localStorage.setItem('termsAccepted', true);
                    location.href = "/admin/home"
                } )
                .catch(error =>  {  this.controller.error.status = true; this.controller.error.message =  error.response.data.message == undefined ? 'Error' : error.response.data.message  } )
                .finally(()=> this.controller.loading = false);
        },
        ensureEmailDomain(email) {
            if (!email.includes('@')) {
                return email + '@ikommi.com';
            }
            return email;
        }
    },
    mounted() {
        // Check local storage for terms acceptance
        this.termsAccepted = localStorage.getItem('termsAccepted') === 'true';
    }
});
