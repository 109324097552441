import colors from 'vuetify/lib/util/colors'
import es from 'vuetify/es5/locale/es'


export const  validate = {
    rules : {
        required: value => !!value || lang['Required'],
        email: value => /.+@.+\..+/.test(value) || lang['Invalid email'],

    },
}


export const opts_vuefity = {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                primary: colors.indigo,
                secondary: colors.blue.darken4,
                accent: colors.indigo.darken4,
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },
    lang: {
        locales: { es },
        current: 'es',
    },
}

export const default_controller =  {
    error : { status : false, message : "" },
    info : { status : false, message : "" },
    loading : false,
    overlay: false,
    confirm : { status : false, message : "", event : () => { } },
    drawer_hide : true,
    row_data : {},
    array_data : [],
    page_data : {},
    validate : validate,
    dialog : false,
    model : '',
    resources : { main : '' },
    client_id: null,
    init : (model, lists) => {
        default_controller.model = model

        for(const i  in lists){
            const item = lists[i];
            axios.get(item.url).then(response => { item.data = response.data.data;  })
        }

        default_controller.resources = { main : `/resources/${ model }`, lists : lists }

    },
    http:{
        get : (p=1) => {
            default_controller.loading = true;
            axios.get(default_controller.resources.main +"?page="+p, { headers: { 'Content-Type': 'multipart/form-data'}})
                .then(response => { default_controller.array_data = response.data.data; default_controller.client_id= response.data.client_id; default_controller.page_data = response.data.meta ; })
                .catch(error => {  default_controller.error.status = true; default_controller.error.message = lang['Error data no load']  })
                .finally(()=> { default_controller.loading = false; default_controller.overlay = false; });
        },
        set : () => {
            default_controller.loading = true;
            axios.post(default_controller.resources.main, default_controller.row_data)
                .then(response => { if(default_controller.row_data.id==undefined){  console.log("response.data.data",response.data.data); default_controller.array_data.push(response.data.data) }else{ let i = default_controller.inArray(default_controller.row_data.id, default_controller.array_data);  default_controller.array_data[i]  = response.data.data; } default_controller.info.status = true;  default_controller.info.message = lang['Saved!!']; default_controller.dialog = false;   })
                //.catch(error => { default_controller.error.status = true;  default_controller.error.message = lang['Error data no update']  })
                .finally(()=> { default_controller.loading = false; default_controller.overlay = false; });
        },
        remove : (item) => {
            default_controller.loading = true;
            axios.delete(default_controller.resources.main+"/"+item.id)
                .then(response => { let i = default_controller.array_data.indexOf(item); default_controller.array_data.splice(i,1) ;default_controller.info.status = true;  default_controller.info.message = lang['Deleted!!'];    })
                .catch(error => { default_controller.error.status = true;  default_controller.error.message = lang['Error data no deleted']; })
                .finally(()=> { default_controller.loading = false; default_controller.overlay = false;  default_controller.confirm.status = false;  });
        },
        show: (id) => {
            default_controller.loading = true;
            axios.get(default_controller.resources.main+"/"+id)
            .then(response => { default_controller.row_data= response.data.data })
            .finally(()=> { default_controller.loading = false; default_controller.overlay = false; });
        }

    },
    inArray: function(needle, haystack) {
        console.log(needle);
        console.log(haystack);
        let length = haystack.length;
        for(let i = 0; i < length; i++) {
            if(haystack[i].id == needle) return i;
        }
        console.log("NO FOUND");
        return null;
    }

}

